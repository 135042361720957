import $ from 'jquery';
import React from 'react';
import Chart from "react-google-charts";
import {UserToken,getUserObjData,getAccessFunctions} from '../function_lib/userToken';
import {CallMapMethod, GetDateDifferecec,CallMapMethod_CBack} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

import Upcoming_Events from '../components/upcoming_events';
import Emp_On_Leave from '../components/emp_on_leave';
import Broadcast from '../components/broadcast';
import Mobile_Widgets from '../components/mobile_widgets';

import './dashboard_new.css';

//import UserManual from '../assets/Operational_Instructions.pdf';

let loginUser="";
let userObject="";
let companyName="";
let userType="";
let clsObject;
class Comp_Dashboard extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        clsObject=this;
        this.state={country:[],line_of_business:[],type_of_clain:[],company:[],group_name:[] };
        this.state={no_entities:0,no_clients:0,no_vendors:0}
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        userType="0";
        
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }

      let ret= getAccessFunctions();
     if(ret==="all"){
      //window.location.href="./dashboard";
     }else{
      window.location.href="./library_master";
     }
    }

      componentDidMount(){
        $("#divEmployee").show();
        this.getEntCliVen();
        var arrCountry=new Array();
        arrCountry.push(["Complaint","Non Compliant"])
        arrCountry.push(["Compliant",parseInt("90")]);
        arrCountry.push(["Non Compliant",parseInt("10")]);

        var arrGroupName=new Array();
        arrGroupName.push(["Month",""]);
        arrGroupName.push(["Jan-2022",parseInt("99")]);
        arrGroupName.push(["Feb-2022",parseInt("90")]);
        arrGroupName.push(["Mar-2022",parseInt("95")]);
        arrGroupName.push(["Apr-2022",parseInt("99")]);
        arrGroupName.push(["May-2022",parseInt("90")]);
        arrGroupName.push(["Jun-2022",parseInt("0")]);
        arrGroupName.push(["July-2022",parseInt("0")]);
        arrGroupName.push(["Aug-2022",parseInt("0")]);
        arrGroupName.push(["Sept-2022",parseInt("0")]);
        arrGroupName.push(["Oct-2022",parseInt("0")]);
        arrGroupName.push(["Nov-2022",parseInt("0")]);
        arrGroupName.push(["Dec-2022",parseInt("0")]);

        this.setState({country:arrCountry});
        this.setState({group_name:arrGroupName});
        this.forceUpdateHandler();
       
      }
   //Get no of Entities/Client/Vendos
      getEntCliVen(){
        var libType=[];
        libType.push({name:"curl_type",value:"getEntityList"});
        libType.push({name:"res_func",value:"callBackEntityList"});
        libType.push({name:"id",value:userObject[0].id});
        libType.push({name:"app_user_id",value:userObject[0].app_user_id});
        libType["res_func"]="callBackEntityList";
        libType["curl_type"]="getEntityList";
        CallMapMethod_CBack(this,libType,function(data,msg){
          //alert(data+msg);
          if(data!=="0"){
            let listD=JSON.parse(data);
            if(listD.length>0){
              clsObject.setState({no_entities:listD[0]["no_of_entities"],
            no_clients:listD[0]["no_of_clients"],no_vendors:listD[0]["no_of_vendors"]},()=>{
              
            })
           
            }
            
          }
          
        });
      }   
     
   
     

    render(){
        
      
        return(<div className="wrapper">
            <Header/>
            {/* <Mobile_Widgets/> */}
            <div class="content">
            <div class="container-fluid mt-4">
                   {/* new acta component */}
                   <div className="row">
<div className="col-lg-4 col-6">
<a href='../entity_module'>
  <div className="small-box dash_opt bg-info">
    <div className="inner">
      <h3>{this.state.no_entities}</h3>
        <p>Manage Client,Vendors Compliances</p>
      <p>Entity Module</p>
    </div>
    <div className="icon">
      <i className="fa fa-cogs"></i>
    </div>
    <a href="../entity_module" className="small-box-footer">
      More info <i className="fa fa-arrow-circle-o-right"></i>
    </a>
  </div>
  </a>
</div>

<div className="col-lg-4 col-6">
<a href="../vendor_audit">
  <div className="small-box dash_opt bg-danger">
    <div className="inner">
      <h3>{this.state.no_vendors}</h3>
        <p>Manage Vendor's Compliances</p>
      <p>Vendor Audit Module</p>
    </div>
    <div className="icon">
      <i className="fa fa-laptop"></i>
    </div>
    <a href="../vendor_audit" className="small-box-footer">
      More info <i className="fa fa-arrow-circle-o-right"></i>
    </a>
  </div>
  </a>
</div>

<div className="col-lg-4 dash_opt col-6">
<a href='../client_site'>
  <div className="small-box bg-warning">
    <div className="inner">
      <h3>{this.state.no_clients}</h3>
      <p>Manage Client Complaince</p>
      <p>Client Site Compliance Module</p>
    </div>
    <div className="icon">
      <i className="fa fa-calendar-minus-o"></i>
    </div>
    <a href="../client_site" className="small-box-footer">
      More info <i className="fa fa-arrow-circle-o-right"></i>
    </a>
  </div>
  </a>
</div>


</div>
                {/* end of new acta component */}

{/* <!-- /.content --> */}
</div>
      </div> 
        <Footer/>
        
      </div>)
    }
}
export default Comp_Dashboard