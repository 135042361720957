import $ from 'jquery';
import React from 'react'

import {
  btnSetColor_CBack,
  GetImgCert_CBack,
  AjaxCallMethod,
  RespBkMthdMapp
} from '../function_lib/common_lib'
import { Cls_Common_Lib } from '../function_lib/cls_common_lib'
import TkMeter from '../assets/images/camera.svg'

let strCheckRoles = ''
let clsObjectEnt
let objImages = []
let indexExisting = 0
let arrExistingFiles = []
let clsEntitySetting
class Comp_Entity_Settings extends React.Component {
  constructor (props) {
    super(props)
    clsObjectEnt = this
    clsEntitySetting = new Cls_Common_Lib()
  }
  componentWillReceiveProps (props) {
    this.props = props
    //alert(this.props.partyInfoData.id);
    //alert(JSON.stringify(props));
    if (typeof props.partyInfoId !== 'undefined') {
      if (props.partyInfoId !== '' && props.partyInfoId !== '0') {
        //    this.setState({partyInfoId:props.partyInfoId});
        //    this.forceUpdateHandler();
      }
    }
  }
  saveLibrary () {
    // GetImgCert(clsObject,"#frmLibCreate","addLibraryList","okSuccLib");
  }

  addEntitySettings (frmName) {
    GetImgCert_CBack(this, frmName, 'updateLogoEntity', function (data, msg) {
      alert(msg)
      if (data === '001') {
        alert('Accessibility Updated Successfully')
      } else {
        alert(
          'Unable to Update Accessibility Record !kindly check and try again'
        )
      }
    })
  }

  ImageThumbBack (flImg, flName, fObject, othInfo = '') {
    //alert(flImg);

    if (othInfo === '') {
      othInfo = 'nm' + indexExisting + ',' + indexExisting
    }
    indexExisting++
    var arrNmValue = othInfo.split(',')
    //arrExistingFiles.push
    arrExistingFiles[arrNmValue[1]] = arrNmValue[0]
    var strSet =
      '<div id="ref_div_' +
      arrNmValue[1] +
      '" class="col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent">' +
      '<i class="icon-close _removeBtn' +
      indexExisting +
      '" param1="' +
      arrNmValue[1] +
      '" aria-hidden="true"></i>' +
      '<a class="gallery-item" href="' +
      flImg +
      '"><img src="' +
      flImg +
      '"/></a>' +
      '</div>'
    if (
      flImg.indexOf('data:video/webm') != -1 ||
      flImg.indexOf('data:video/mp4') != -1
    ) {
      strSet =
        '<div id="ref_div_' +
        arrNmValue[1] +
        '" class="col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent">' +
        '<i class="icon-close _removeBtn' +
        indexExisting +
        '" param1="' +
        arrNmValue[1] +
        '" aria-hidden="true"></i>' +
        '<a class="gallery-item" href="' +
        flImg +
        '"><video controls type="video/mp4" src="' +
        flImg +
        '"/></a>' +
        '</div>'
    }

    //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
    objImages.push({ name: arrNmValue[1], value: flImg })
    //alert(objImages.length);
    $('#lsImages').val('{"objs":' + JSON.stringify(objImages) + '}')
    //alert(strSet);
    $('.imgLibThumb').append(strSet)
    if (fObject !== null) {
      fObject.val('')
    }
    //     $('.gallery-item').magnificPopup({
    //       type: 'image',
    //       gallery: {
    //           enabled: true
    //       }
    //   });
    RespBkMthdMapp(
      clsObjectEnt,
      '_removeBtn' + indexExisting,
      'rmFileProc',
      '2'
    )
    //imgLibThumb
  }
  componentDidMount () {
    clsEntitySetting.AjaxCallMethod(clsObjectEnt, 'okSuccCNOT')
  }
  render () {
    return (
      <div
        className='modal fade bd-example-modal-xl'
        id='divEntitySettings'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-xl' role='document'>
          <div className='modal-content'>
            <div className='modal-header bg-primary'>
              <h5 className='modal-title text-white' id='exampleModalLabel'>
                Entity Settings
              </h5>
              <button
                type='button'
                className='close text-white'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true' className='text-white'>
                  &times;
                </span>
              </button>
            </div>
            <form method='POST' id='frmEntitySettings' name='frmEntitySettings'>
              <div className='modal-body'>
                <div className='row'>
                  {/* <!-- left column --> */}
                  <div className='col-md-12'>
                    <div className='card card-danger'>
                      <input
                        type='hidden'
                        name='res_func'
                        id='res_func'
                        value='okSucc'
                      />
                      <input
                        type='hidden'
                        name='curl_type'
                        id='curl_type'
                        value='updateLogoEntity'
                      />
                      <input
                        type='hidden'
                        name='m_type'
                        id='m_type'
                        value='updateLogoEntity'
                      />
                      <input
                        type='hidden'
                        name='app_user_id'
                        id='app_user_id'
                        value=''
                      />
                      <input
                        type='hidden'
                        name='entity_id'
                        id='entity_id'
                        value={
                          typeof this.props.partyInfoData.enc_id === 'undefined'
                            ? '0'
                            : this.props.partyInfoData.enc_id
                        }
                      />
                      <input type='hidden' name='lsImages' id='lsImages' />

                      <div className='card-body'>
                        <div className='row audioLibThumb imgLibThumb'></div>
                        {/* <img setTo="customFileBr" className="_brFileThumb updthumb entity_icon_img" src={TkMeter} alt=""/> */}
                        <input
                          type='file'
                          ref_img='ImageThumbBack'
                          multiple
                          name='customFileBr1'
                          id='customFileBr1'
                          className='updImgThumb12'
                        />
                        <div id='comp_access_roles'></div>
                        <div className='col-md-6 respSSL'></div>
                        <div id='testing2dd2222'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <div id='getReponse_add_client' style={{ color: 'red' }}></div>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-dismiss='modal'
                >
                  Close
                </button>
                <button
                  type='button'
                  onClick={() => this.addEntitySettings('#frmEntitySettings')}
                  className='btn btn-primary'
                >
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
export default Comp_Entity_Settings
