import $, { extend } from 'jquery';
import React from 'react';
import { UserToken, getUserObjData } from '../function_lib/userToken';
import { CallMapMethod, AjaxCallMethod } from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import Library_Opt_Modules from './library_opt_modules';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';


let loginUser = "";
let userObject = "";
let companyName = "";
let clsObject;
class Library_Master extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.getItem("userData") != null) {
      userObject = getUserObjData();

      //alert(JSON.stringify(userObject));
      loginUser = userObject[0].first_name;
      companyName = userObject[0].first_name;

    }
  }
  callBackNg(data, msg) {
    //  alert(data);
    if (data.trim() !== "" && data.trim() !== "0") {
      var listItems = JSON.parse(data);
      var str = "";
      var countEmp = 0;

      for (var i = 0; i < listItems.length; i++) {
        countEmp++;

        str += "<tr>" +
          "<td>" + (i + 1) + "</td>" +
          "<td>" + listItems[i]["p_details"] + "</td>" +
          "<td>" + listItems[i]["details"] + "</td>" +
          "<td>" + "" + "</td>" +
          "</tr>"
      }
      //$("#tbData").html(str);
      //$(".total_emp").html(countEmp);
    }
  }
  getCompanyMaster() {

  }
  callBackParent(data, msg) {
    // alert(data);
    var strParentId = "<option value=\"0\">" + "-Select-" + "</option>";
    if (data.trim() !== "" && data.trim() !== "0") {

      var listParent = JSON.parse(data);
      for (var p = 0; p < listParent.length; p++) {
        strParentId += "<option value=\"" + listParent[p]["id"] + "\">" + listParent[p]["details"] + "</option>";
      }
    }
    $("#parent_id").html(strParentId);
  }
  getCheckListParent() {
    var map = [];
    map.push({ name: "curl_type", value: "getCheckList" });
    map.push({ name: "res_func", value: "callBackParent" });
    map.push({ name: "local_token", value: userObject[0].app_user_id });
    map.push({ name: "parent_id", value: "0" });
    map.push({ name: "type_id", value: "10" });
    map["res_func"] = "callBackParent";
    map["curl_type"] = "getCheckList";
    CallMapMethod(this, map);
  }
  componentDidMount() {
    clsObject = this;
    // this.getCompanyMaster();
    // this.getCheckListParent();
    /*
     var empCategory=[];
     empCategory.push({name:"curl_type",value:"getEmployeeCategory"});
     empCategory.push({name:"res_func",value:"callBackEmpCate"});
     empCategory.push({name:"local_token",value:userObject[0].app_user_id});
     empCategory.push({name:"ref2_id",value:userObject[0].id});
     empCategory["res_func"]="callBackEmpCate";
     empCategory["curl_type"]="getEmployeeCategory";
     CallMapMethod(this,empCategory);
   */
    AjaxCallMethod(this, "okSuccCNOT");
    $("#app_user_id").val(userObject[0].id);
  }
  callBackEmpCate(data, msg) {
    //alert(data);
    var listEmpCategor = JSON.parse(data);
    var strTmp = "<option value=\"0\">-select-</option>";
    for (var l = 0; l < listEmpCategor.length; l++) {
      strTmp += "<option value=\"" + listEmpCategor[l]["id"] + "\">" + listEmpCategor[l]["category_name"] + "</option>"
    }
    $("#party_mapping_id").html(strTmp);

  }
  okSucc(data, msg) {
    //alert(data+"===="+msg);
    if (data === "001") {

      $("#frmCreate").find("input[type=text]").val("");
      //$("#frmCreate").find()
      $(".respSSL").html(msg);
      clsObject.getCompanyMaster();
    } else {
      $(".respSSL").html(msg);
    }
  }
  render() {
    return (<div>
      <Header />
      <div className="content">
        <div className="container-fluid mt-4">
          <div className="row">
            <div className="col-md-2">

              {/* <!-- Widget: user widget style 2 --> */}
              <div className="card card-widget widget-user-2 shadow ">
                {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}


                {/* <!-- /.widget-user -->  */}
              </div>

              {/* <!-- Widget: user widget style 2 --> */}
              <div className="card card-widget widget-user-2 shadow ">
                {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
                <Library_Opt_Modules />

                {/* <!-- /.widget-user -->  */}
              </div>
            </div>
            <div className="col-lg-10 col-md-10 col-sm-12 col-12">
              <section className="content">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">ACTA E-Library</h3>

                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                        <i class="fas fa-minus"></i></button>
                      <button type="button" class="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                        <i class="fas fa-times"></i></button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div class="post">
                          <div className="user-block">

                            <span className="username1">
                              <a href="javascript:void(0)"><b>Hi ! {companyName},</b></a>
                            </span>

                          </div>

                          <p>
                            Welcome to ACTA,Our Automated Compliance Tracking Application.You are currently using E-Library
                          </p>
                          <div class="card">
                            <div class="card-header">
                              <h3 class="card-title">
                                <i class="fa fa-long-arrow-right"></i>
                                ACTA has 4 Modules to Ease your compliances.
                              </h3>
                            </div>

                            <div class="card-body">
                              <p class=" text-success">1.Entity Compliance Module</p>
                              <p class=" text-info">2.Vendor Audit Module</p>

                              <p class="text-primary">3.Client Compliance - Contract Compliance Module</p>

                              <p class="text-danger">4.E-Library</p>
                            </div>

                          </div>
                          {/* thumbnail */}
                          <div class="card-body">
                      <div className='row'>
                        <div className='col-3'>
                        <div class="alert alert-danger alert-dismissible">
                  {/* <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button> */}
                  <h5><i class="icon fas fa-ban"></i>50,000+</h5>
                  Records
                </div>
                        </div>
                        <div className='col-3'>
                        <div class="alert alert-info alert-dismissible">
                  {/* <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button> */}
                  <h5><i class="icon fas fa-info"></i>5000+</h5>
                  Important Judgment
                </div>
                        </div>
                      <div className='col-3'>

                      <div class="alert alert-warning alert-dismissible">
                  {/* <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button> */}
                  <h5><i class="icon fas fa-exclamation-triangle"></i>4000+</h5>
                     Rules,Acts & Subjects
                </div>

                      </div>
                    {/* <div className='col-3'>
                    <div class="alert alert-success alert-dismissible">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                  <h5><i class="icon fas fa-check"></i>Your Subscription</h5>
                  Success alert preview. This alert is dismissable.
                </div>
                      </div>   */}
                      </div>
              
             
               
              </div>
              <p>
              We have one of the Biggest Library which contains contents in relation with HR and Employment Law. However, sometime you may not find what you are looking for, please write us at helpdesk@indithinkk.com same will be updated in next 24 hours

              </p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer />

    </div>)
  }
}
export default Library_Master;