import $, { extend } from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';

import Comp_Add_Branches from '../components/comp_add_branches';
import Comp_Add_Client from '../components/comp_add_client';
import Comp_Add_Vendor from '../components/comp_add_vendor';

import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';


let loginUser="";
let userObject="";
let companyName="";
let clsObject;
class User_Management extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= JSON.parse(localStorage.getItem("userData"));
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      }
      callBackNg(data,msg){
        //  alert(data);
        if(data.trim()!=="" && data.trim()!=="0"){
          var listItems=JSON.parse(data);
          var str="";
          var countEmp=0;
        
          for(var i=0;i<listItems.length;i++){
              countEmp++;
             
            str+="<tr>"+
            "<td>"+(i+1)+"</td>"+
             "<td>"+listItems[i]["p_details"]+"</td>"+
             "<td>"+listItems[i]["details"]+"</td>"+
             "<td>"+""+"</td>"+
             "</tr>"
          }
          //$("#tbData").html(str);
          //$(".total_emp").html(countEmp);
        }
    }
 getCompanyMaster(){
    
 }

 addBranches(){
     alert("I am in branch");
 }

 callBackParent(data,msg){
    // alert(data);
    var strParentId="<option value=\"0\">"+"-Select-"+"</option>";
    if(data.trim()!=="" && data.trim()!=="0"){
       
        var listParent=JSON.parse(data);
        for(var p=0;p<listParent.length;p++){
            strParentId+="<option value=\""+listParent[p]["id"]+"\">"+listParent[p]["details"]+"</option>";
        }
    }
    $("#parent_id").html(strParentId);
 }
 getCheckListParent(){
    var map=[];
    map.push({name:"curl_type",value:"getCheckList"});
    map.push({name:"res_func",value:"callBackParent"});
    map.push({name:"local_token",value:userObject[0].app_user_id});
    map.push({name:"parent_id",value:"0"});
    map.push({name:"type_id",value:"10"});
    map["res_func"]="callBackParent";
    map["curl_type"]="getCheckList";
    CallMapMethod(this,map);
 }   
componentDidMount(){
    clsObject=this;
// this.getCompanyMaster();
// this.getCheckListParent();
 /*
  var empCategory=[];
  empCategory.push({name:"curl_type",value:"getEmployeeCategory"});
  empCategory.push({name:"res_func",value:"callBackEmpCate"});
  empCategory.push({name:"local_token",value:userObject[0].app_user_id});
  empCategory.push({name:"ref2_id",value:userObject[0].id});
  empCategory["res_func"]="callBackEmpCate";
  empCategory["curl_type"]="getEmployeeCategory";
  CallMapMethod(this,empCategory);
*/
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].id);
}
callBackEmpCate(data,msg){
    //alert(data);
    var listEmpCategor=JSON.parse(data);
    var strTmp="<option value=\"0\">-select-</option>";
    for(var l=0;l<listEmpCategor.length;l++){
        strTmp+="<option value=\""+listEmpCategor[l]["id"]+"\">"+listEmpCategor[l]["category_name"]+"</option>"
    }
    $("#party_mapping_id").html(strTmp);

}
okSucc(data,msg){
    //alert(data+"===="+msg);
    if(data==="001"){
        alert("Record Added Successfully");
        $("#frmCreate").find("input[type=text]").val("");
        //$("#frmCreate").find()
        $(".respSSL").html(msg);
        window.location.reload();
        //clsObject.getCompanyMaster();
    }else{
        $(".respSSL").html(msg);
    }
}
      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row">
<div className="col-md-12 col-lg-12"> 
        
<section class="content">
<div class="container-fluid">
<div class="card card-danger">
<div class="card-header">
                                <h3 class="card-title">Create User</h3>
                            </div>
                            <form class="form-group" id="frmCreate" name="frmCreate">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="addUsersNew" />
                            <input type="hidden" name="m_type" id="m_type" value="addUsersNew" />
                            <input type="hidden" name="unit_id" id="unit_id" value="3" />
                            <input type="hidden" name="parent_id" id="parent_id" value="0"/>
                            <input type="hidden" name="category_id" id="category_id" value="4"/>
                            <div class="card-body">
                                <div class="row">
                                <div class="col-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Name of Company*</label>
                                            <div class="input-group input-group-sm">
                                                <input type="text" id="company_name" name="company_name" class="form-control" placeholder="Company Name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">PAN *</label>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" id="company_pan" name="company_pan" placeholder="PAN" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">GST *</label>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" id="company_gst" name="company_gst" placeholder="GST" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">CIN *</label>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" id="company_cin" name="company_cin" placeholder="CIN" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">TAN *</label>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" id="company_tan" name="company_tan" placeholder="TAN" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Contact Person *</label>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" id="company_contact_person" name="company_contact_person" placeholder="Contact Person" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Contact No *</label>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" id="company_contact_no" name="company_contact_no" placeholder="Contact No" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Email *</label>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" id="company_email" name="company_email" placeholder="Email" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Website *</label>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" id="company_website" name="company_website" placeholder="Website" />
                                            </div>
                                        </div>
                                    </div>
                                <div class="col-9">
                                <div class="form-group">
                                            <label for="exampleInputEmail1">Address *</label>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" id="company_address" name="company_address" placeholder="Full Address" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                                <div class="row">
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Remark :</label>

                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="form-group">
                                            <div class="card-body pad">
                                                <div class="mb-3">
                                                    <textarea id="company_details" name="company_details" class="textarea" placeholder="Place some text here"
                                                              ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div class="col-3">
                                    <div class="form-group">
                                            <label for="exampleInputEmail1">UserName *</label>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" id="entity_user_name" name="entity_user_name" placeholder="User Name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                    <div class="form-group">
                                            <label for="exampleInputEmail1">Password *</label>
                                            <div class="input-group input-group-sm">
                                                <input type="password" class="form-control" id="entity_password" name="entity_password" placeholder="Password" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                <div class="row">
                                    <div class="col-3">
                                        <div class="form-group">

                                            <div class="input-group input-group-sm">
                                                <button type="button" class="btn btn-primary _btnSetColor">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </form>
    </div>
    </div>
</section>
      
</div>
    
</div>
</div>
</div>
<Footer/>

          </div>)
      }
}
export default User_Management;