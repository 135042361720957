import { type } from 'jquery';
import React,{ useState,useEffect } from 'react';

export const Comp_Read_More=(itemData)=>{
    // useEffect(() => {
    //     console.log("Change in child detected, rendering new data");
    // }, [itemData]);

    //alert("in=="+JSON.stringify(itemData));
    
  
    
    return(<div className="modal fade bd-example-modal-xl"  id="divCompReadMore" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="comp_read_more_title"></h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmC" name="frmC">
       
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div  className="card card-danger">
                      <div style={{"padding":"3px 3px"}} id="comp_read_more">
                        </div>
                        
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}
export default Comp_Read_More;