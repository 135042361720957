import $ from 'jquery';
import React from 'react';
import LogoImg from '../assets/images/logo_p_small.png';
//import LogoImg from '../assets/images/logo_mini.jpeg';
import {UserToken,getUserObjData,getAccessFunctions} from '../function_lib/userToken';
import {setProfilePic,defaultClick,getAccessRolesNew,CallMapMethod,CallMapMethod_CBack,appGetUrlPath} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import UserDefault from '../assets/images/9.png';


import './header.css';
let listRoles;
let loginUser;
let userObject;
let companyName="";
let clsObject;
let parentIcon="";
let appUrtPath="";
class Header extends React.Component{
  logout(){
    localStorage.clear();
    window.location.href="../";
  }

  

  constructor(props){
  super(props);
    listRoles=[];
    appUrtPath=appGetUrlPath();
    if(localStorage.getItem("userData")!=null){
      userObject= getUserObjData();
      //alert(JSON.stringify(userObject));
      loginUser=userObject[0].first_name;
      parentIcon=userObject[0].p_icon;
      if(typeof userObject[0].company_name!=="undefined"){
        companyName=userObject[0].company_name;
      }
  }
  if(userObject[0].id==="VKeBS5Qp44FXKLwPLhb+ZA=="){
    listRoles.push({"id":"5","name":"Masters","parent_id":"0","red_link":"../dashboard12","description":"","display_option":"menu"});
    listRoles.push({"id":"51","name":"Library","parent_id":"5","red_link":"../library_master","description":"","display_option":"menu"});
    listRoles.push({"id":"51","name":"Entity Management","parent_id":"5","red_link":"../entity_management","description":"","display_option":"menu"});
    listRoles.push({"id":"51","name":"User Management","parent_id":"5","red_link":"../user_management","description":"","display_option":"menu"});
    listRoles.push({"id":"51","name":"View Entity","parent_id":"5","red_link":"../view_entity","description":"","display_option":"menu"});
    listRoles.push({"id":"51","name":"Entity Compliance Mgmt","parent_id":"5","red_link":"../compliance_list_mgmt","description":"","display_option":"menu"});
    listRoles.push({"id":"51","name":"Compliance Masters","parent_id":"5","red_link":"../compliance_master_mgmt","description":"","display_option":"menu"});
  }
  
  let ret=getAccessFunctions();
    if(ret==="all"){
      listRoles.push({"id":"1","name":"Dashboard","parent_id":"0","red_link":"../dashboard","description":"","display_option":"menu"});
      listRoles.push({"id":"1","name":"Branches","parent_id":"0","red_link":"../branches_list?rf=uiu78jjt676","description":"","display_option":"menu"});
      listRoles.push({"id":"4","name":"Compliance List","parent_id":"0","red_link":"../compliance_list","description":"","display_option":"menu"});
      listRoles.push({"id":"41","name":"Vendor Compliance","parent_id":"4","red_link":"../compliance_list/vendor","description":"","display_option":"menu"});
      listRoles.push({"id":"42","name":"Client Compliance","parent_id":"4","red_link":"../compliance_list/client","description":"","display_option":"menu"});
      listRoles.push({"id":"43","name":"Branch Compliance","parent_id":"4","red_link":"../compliance_list/branches","description":"","display_option":"menu"});

      listRoles.push({"id":"1","name":"Registration & Licenses","parent_id":"0","red_link":"../registration_licenses","description":"","display_option":"menu"});
      //listRoles.push({"id":"1","name":"Registration & Licenses","parent_id":"0","red_link":"../registration_licenses","description":"","display_option":"menu"});
      listRoles.push({"id":"1","name":"Notices","parent_id":"0","red_link":"../notices","description":"","display_option":"menu"});
      
      // listRoles.push({"id":"11","name":"Library","parent_id":"0","red_link":"../notices","description":"","display_option":"menu"});
      // listRoles.push({"id":"12","name":"Consolidated Labour Law Ready Reckoner","parent_id":"11","red_link":"../Consolidated_Labour_Law_Ready _Reckoners.xlsx","description":"","display_option":"menu"});
      // listRoles.push({"id":"13","name":"Laws, Rules & Notifications","parent_id":"11","red_link":"https://indithinkk.com/index.php/gazette-notifications/","description":"","display_option":"menu"});
      
      listRoles.push({"id":"2","name":"Abstract & Notices","parent_id":"0","red_link":"../abstract_notices","description":"","display_option":"menu"});
      //listRoles.push({"id":"3","name":"E-Library","parent_id":"0","red_link":"../e_library","description":"","display_option":"menu"});
      listRoles.push({"id":"3","name":"E-Library","parent_id":"0","red_link":"../library_master","description":"","display_option":"menu"});
      
    }else{
      listRoles.push({"id":"3","name":"E-Library","parent_id":"0","red_link":"../library_master","description":"","display_option":"menu"});
    }
   
    // if(localStorage.getItem("userData")!=null){
    //   UserToken.setUserObject(localStorage.getItem("userData"));
    //   listRoles= UserToken.getAccessRoles();
    // }
    if(localStorage.getItem("userDS")!=null){
      userObject= JSON.parse(localStorage.getItem("userDS"));
      //alert(JSON.stringify(userObject));
      loginUser=userObject[0].first_name;
      if(typeof userObject[0].company_name!=="undefined"){
        companyName=userObject[0].company_name;
      }
  }
  }
  callBackNg(data,msg){
   var check=JSON.parse(data);
   if(check.length==0){
    // alert("Invalid User !Kindly contact to Admin")
    localStorage.clear();
    window.location.href="../";
   }
  }
  getNotificationCount(){
    var libType=[];
      libType.push({name:"curl_type",value:"getCountNotifications"});
      libType.push({name:"res_func",value:"callBackEntityList"});
      libType.push({name:"app_user_id",value:userObject[0].app_user_id});
      libType.push({name:"status",value:"allow"});
      libType["res_func"]="callBackEntityList";
      libType["curl_type"]="getCountNotifications";
      CallMapMethod_CBack(this,libType,function(data,msg){
        //alert(data);
        if(data!=="0" && data!==""){
          let listCount=JSON.parse(data);
          //alert(listCount.length);
          for(let i=0;i<listCount.length;i++){
            if(listCount[i].counts!==0 && listCount[i].counts!=="0"){
              $("#count_notification").html(listCount[i].counts);
              $("#compliance_count").html(listCount[i].counts);
            }
          }
         
        }
      });
  }
  componentDidMount(){

    // clsObject=this;
    // var mapCat=[];
    // mapCat.push({name:"curl_type",value:"getAccessToken"});
    // mapCat.push({name:"res_func",value:"callBackAccess"});
 
    // mapCat["res_func"]="callBackAccess";
    // mapCat["curl_type"]="getAccessToken";
    // CallCMSMapMethod(this,mapCat);
    this.getNotificationCount();
    if(listRoles.length!=0){
      //alert("i am in ");
      var listHtm=getAccessRolesNew(listRoles);
     
     $("#menu_opt").append(listHtm);
    }
    
  }
 
    render(){
        return(//  <!-- Navbar -->
          <div>
          <nav className="main-header navbar navbar-expand-md  navbar-light navbar-white my-shadow">
          <div className="container-fluid "> <a href="/dashboard" className="navbar-brand"> <img src={parentIcon!==""?appUrtPath+"app_user_img/"+parentIcon:LogoImg} alt="HrTech" className="brand-image elevation-3 img-thumbnail" /> <span className="brand-text " ></span> </a>
            <button className="navbar-toggler order-1 me-hide" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span> </button>
            <div className="collapse navbar-collapse order-3" id="navbarCollapse"> 
           
              {/* <!-- Left navbar links -->  */}
              
              {/* <!-- SEARCH FORM -->  */}
              
            </div>
          
            
            {/* <!-- Right navbar links --> */}
            <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
            {/* <select className="form-control">
                 <option>Year</option>
                 <option>2021</option>
            </select> */}
              {/* <!-- Messages Dropdown Menu --> */}
              <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i style={{fontSize:"2em"}} class="fa fa-bell"></i>
          <span class="badge badge-warning navbar-badge" id="count_notification"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="../recent_notification" class="dropdown-item">
            <i class="fa fa-envelope mr-2"></i>Recent Compliance
            <span class="badge badge-warning float-right text-muted text-sm" id="compliance_count"></span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fa fa-users mr-2"></i>Recent Notices
            <span class="float-right text-muted text-sm" id="notices_count"></span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fa fa-file mr-2"></i>ACTA Updates
            <span class="float-right text-muted text-sm" id="acta_count"></span>
          </a>
          <div class="dropdown-divider"></div>
          {/* <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a> */}
        </div>
      </li>
        
            
              <li className="nav-item dropdown12"> <a className="nav-link" data-toggle="dropdown" href="#"> <i style={{fontSize:"2em"}} className="fa fa-user fa-2x"></i> </a>
                <div id="dropdown12" className="dropdown-menu dropdown-menu-lg dropdown-menu-right"> 
                  <div className="dropdown-divider"></div>
                  <a href="#" className="dropdown-item"> <i className="fa fa-book mr-2"></i> <b>Entity:</b> {companyName}</a>
                  <a href="#" className="dropdown-item"> <i className="fa fa-user mr-2"></i> Welcome {loginUser}</a>
                  <div className="dropdown-divider"></div>
                  
                  <a href="javascript:void(0)" onClick={()=>this.logout()} className="dropdown-item"> <i className="fa fa-sign-out mr-2"></i> Logout </a>
                  <div className="dropdown-divider"></div>
                  </div>
              </li>
            </ul>
          </div>
        </nav>
          <nav class="navbar navbar-expand-lg my-bg-color-1 my-shadow">
          <a class="navbar-brand text-white ml-3" href="#"><i class="fa fa-user-circle"></i> {loginUser}  </a>
          <ul class="navbar-nav">
              
          </ul>
        
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#nav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"><i class="fa ffg fa-bars "></i></span>
          </button>
        
          <div class="collapse navbar-collapse" id="nav">
        
        
              <ul id="menu_opt" class="navbar-nav bg-light-my m-0 ml-lg-auto p-3 p-lg-0 m-auto1">
              <li class="d-inline d-lg-none">
                <button data-toggle="collapse" data-target="#nav" class="close float-right">&times;</button>
            </li>
            <li class="nav-item d-lg-none d-md-none d-sm-none  "><img src={UserDefault} width="80" class="img-circle img-thumbnail img-fluid m-auto text-cente" />
				<h6 class="mt-4">Good-Morning {loginUser} </h6>
				<div class="border-bottom w-100 mt-1 mb-1"></div>
			
			</li>
              </ul>
          </div>
      </nav>


      </div>
          )
    }
}
export default Header