import $ from 'jquery';
import React from 'react';

import {btnSetColor_CBack,GetImgCert_CBack,AjaxCallMethod,RespBkMthdMapp} from '../function_lib/common_lib';

export function bulkImportCall (frmName) {
    GetImgCert_CBack(this, frmName, 'bulkImportCompliance', function (data, msg) {
      alert(data+msg)
      $("#getReponse_add_client").html(msg);
      if (data === '001') {
        alert('Accessibility Updated Successfully')
      } else {
        alert(
          'Unable to Update Accessibility Record !kindly check and try again'
        )
      }
    })
  }
export const Bulk_Import_Compliance=(props)=>{
    return(<div
        className='modal fade bd-example-modal-xl'
        id='divBulkImportCompliance'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-xl' role='document'>
          <div className='modal-content'>
            <div className='modal-header bg-primary'>
              <h5 className='modal-title text-white' id='exampleModalLabel'>
                Bulk Import
              </h5>
              <button
                type='button'
                className='close text-white'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true' className='text-white'>
                  &times;
                </span>
              </button>
            </div>
            <form method='POST' id='frmBulkImport' name='frmBulkImport'>
              <div className='modal-body'>
                <div className='row'>
                  {/* <!-- left column --> */}
                  <div className='col-md-12'>
                    <div className='card card-danger'>
                      <input
                        type='hidden'
                        name='res_func'
                        id='res_func'
                        value='okSucc'
                      />
                      <input
                        type='hidden'
                        name='curl_type'
                        id='curl_type'
                        value='updateLogoEntity'
                      />
                      <input
                        type='hidden'
                        name='m_type'
                        id='m_type'
                        value='updateLogoEntity'
                      />
                      <input
                        type='hidden'
                        name='app_user_id'
                        id='app_user_id'
                        value=''
                      />
                      <input
                        type='hidden'
                        name='entity_id'
                        id='entity_id'
                        value={
                          typeof props.partyInfoData === 'undefined'
                            ? '0'
                            : props.partyInfoData.enc_id
                        }
                      />
                      <input type='hidden' name='lsImages' id='lsImages' />

                      <div className='card-body'>
                        <div className='row audioLibThumb imgLibThumb'></div>
                        {/* <img setTo="customFileBr" className="_brFileThumb updthumb entity_icon_img" src={TkMeter} alt=""/> */}
                        <input
                          type='file'
                          ref_img='ImageThumbBack'
                          multiple
                          name='customFileBr1'
                          id='customFileBr1'
                          className='updImgThumb12'
                        />
                        <div id='comp_access_roles'></div>
                        <div className='col-md-6 respSSL'></div>
                        <div id='testing2dd2222'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <div id='getReponse_add_client' style={{ color: 'red' }}></div>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-dismiss='modal'
                >
                  Close
                </button>
                <button
                  type='button'
                  onClick={() => bulkImportCall('#frmBulkImport')}
                  className='btn btn-primary'
                >
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>);
}
export default Bulk_Import_Compliance;