import $, { extend } from 'jquery';
import React from 'react';
import QueryString from 'query-string';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {getComplianceType,getPeriodicity,getResponsibility,getComplianceCategory,getMonths} from '../function_lib/common_data';
import {StateMaster} from '../function_lib/city_india';
import {CallMapMethod,AjaxCallMethod,RespBkMthdMapp,getSearchOutPut,CallMapMethod_CBack} from '../function_lib/common_lib';

import {ComplianceList} from '../function_lib/compliance_list';
import Comp_Submit_Compliance from '../components/comp_submit_compliance';
import Bulk_Import_Compliance from '../components/bulk_import_compliance';
import Header from './header';
import Footer from './footer';
import Option_Module from './option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';


let loginUser="";
let userObject="";
let companyName="";
let clsObject;
let listCompliance=[];
let rf=0;
let queries;



/* Compliance Type */
let vComplianceType=[];
let vListPeriodicity=[];
let vListReponsibility=[];
let vListComplianceCate=[];

let stateList=[];
let vListMonths=[];

class Compliance_Master_Mgmt extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };
    constructor(props){
        super(props);
        clsObject=this;
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        this.state={isAllow:false,stComplianceList:[],stCompData:[]};
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
         
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;

          stateList=StateMaster();

          listCompliance=ComplianceList();
          vComplianceType= getComplianceType();
          vListPeriodicity=getPeriodicity();
          vListReponsibility=getResponsibility();
          vListComplianceCate=getComplianceCategory();
          vListMonths=getMonths();
      }
      if(props !=null){
        queries = QueryString.parse(this.props.location.search);
        if(queries.rf!=null){
          rf=queries.rf;
        }
      }
      
       
    }

setOnChanageHidden(vTarget,vValue){
  let indexV=vValue.nativeEvent.target.selectedIndex;
  //alert( vValue.nativeEvent.target[indexV].text);
  //alert(vValue);
  $("#"+vTarget).val( vValue.nativeEvent.target[indexV].text);
}


/* Search Parameters and Settings */
   
  setMonths(){
    // let strMonths="<option value=\"0\">"+"-Months-"+"</option>";
    // for(let i=0;i<filtMonths.length;i++){
    //   strMonths+="<option value=\""+filtMonths[i]["id"]+"\">"+filtMonths[i]["mth_year"]+"</option>";
    // }
    // $("#ser_month").html(strMonths);
  }   

  
  
/*End of Search Parameters and Settings */     
   
     
    getComplianceList(){
      //alert("Check rf=="+rf);
      var libType=[];
      libType.push({name:"curl_type",value:"getCompliacenMaster"});
      libType.push({name:"res_func",value:"callBackEntityList"});
      libType.push({name:"id",value:userObject[0].id});
      libType.push({name:"app_user_id",value:userObject[0].app_user_id});
      libType.push({name:"branch_id",value:rf});
      libType.push({name:"type_id",value:"4"});
      libType.push({name:"category_id",value:1});
      libType["res_func"]="callBackEntityList";
      libType["curl_type"]="getCompliacenMaster";
      CallMapMethod_CBack(this,libType,function(data,msg){
        //alert(msg);
        //alert(data);
        //$("#tbData").html(msg+data);
        //return;
        if(data!=="0"){
          let listD=JSON.parse(data);
          alert(listD.length);
          if(listD.length>0){
              clsObject.setState({stComplianceList:listD})
              //clsObject.forceUpdateHandler();
          }
        }
      });
     }
     
 getSerComplianceList(){

  let serBranchId=0;
 
 
 let serMonthId=0;
//  if($("#ser_month").val()!=="" && $("#ser_month").val()!=="0"){
//   serMonthId=$("#ser_month").val();
//  }
  
  

  var libType=[];
  libType.push({name:"curl_type",value:"getComplianceList"});
  libType.push({name:"res_func",value:"callBackEntityList"});
  libType.push({name:"id",value:userObject[0].id});
  libType.push({name:"app_user_id",value:userObject[0].app_user_id});
  libType.push({name:"branch_id",value:serBranchId});
  libType.push({name:"type_id",value:"4"});
  //alert(serMonthId);
  if(serMonthId!==0 && serMonthId!=="0"){
    libType.push({name:"month_id",value:serMonthId});
  }
  
  libType.push({name:"category_id",value:1});
  libType["res_func"]="callBackEntityList";
  libType["curl_type"]="getComplianceList";
  CallMapMethod_CBack(this,libType,function(data,msg){
    //alert(data);
    //$("#tbData").html(msg+data);
    //return;
    if(data!=="0"){
      let listD=JSON.parse(data);
      //alert(listD.length);
      if(listD.length>0){
          clsObject.setState({stComplianceList:listD})
          //clsObject.forceUpdateHandler();
      }
    }
  });
 }
 /* Set Compliance Master Data and Log */
 setComplianceFillingData(compData){
  this.setState({stCompData:compData},()=>{
    RespBkMthdMapp(clsObject,"custom-file-input","");
  });
  this.forceUpdateHandler();
 }    
 
componentDidMount(){
    
 //this.getCompanyMaster();
 //this.getCheckListParent();


this.getComplianceList();

//this.getClientVendorBranchList();
  AjaxCallMethod(this,"okSuccCNOT");
  RespBkMthdMapp(clsObject,"_setSearchKeyUp","getSearch");
  
$("#app_user_id").val(userObject[0].app_user_id);
}
okSucc(data,msg){
  //alert("On Submit=="+data);
  if(data==="001"){
    $(".respSSL").html(msg);
    alert(msg);
    window.location.reload();

  }else{
    $(".respSSL").html("Kindly check fill records properly");
  }
}
//Edit Modules Functions
      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row">

      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="card">
      <div className="row mt-2 mb-2">
        
    <div className="col-lg-2 col-md-2 col-sm-4">
    <select  className="form-control form-control-sm" id="ser_month" name="ser_month">
    <option value={"0"}>--Select-</option>
                                                {vListMonths.map((item,index)=>{
                                                  return <option value={item["value"]}>{item["display"]}</option>
                                                })}
              </select>
    </div>
    <div className="col-lg-2 col-md-2 col-sm-4">
    <select className="form-control form-control-sm" id="ser_status" name="ser_status">
                <option value="0">-States-</option>
                {stateList.length>0?stateList.map((item,index)=>{
                                                    return (<option text={item.name}  value={item.location_id}>{item.name}</option>)
                                                  }):''}
              </select>
    </div>
    
    <div className='col-lg-4 col-md-4 col-sm-4'>
    <div className="form-group">
              <div className="input-group">
                    <input autoComplete='off' type="text" id="col78" name="col78" className="_setSearchKeyUp form-control" />
                  </div>
              </div>
  </div>
    <div className="col-lg-2 col-md-2 col-sm-4 vcenter-item">
    <div className="input-group">
                    <div className="input-group-prepend">
                    <div className='col-12 '>
                      <div onClick={()=>this.getSerComplianceList()}  style={{marginRight:"10px"}} className="btn btn-danger">Search</div>
                      <div  className="btn btn-danger">Reset</div>
                      </div>
                    </div>
                  </div>
     
    </div>
    <div className="col-lg-2 col-md-2 col-sm-4">
    <div className="input-group">
                    
                    <div className="input-group-prepend">
                    <div className='col-4 d-flex'>
            <a href="javascript:void(0)" className="nav-link  hover"><h6><i className="fa fa-user-alt font-small text-orange"></i><span class="float-left badge bg-primary" data-toggle="modal" data-target="#divCreateCompliance">Add New Compliance</span></h6></a>
            <a href="javascript:void(0)" className="nav-link  hover"><h6><i className="fa fa-user-alt font-small text-orange"></i><span class="float-left badge bg-danger" data-toggle="modal" data-target="#divBulkImportCompliance">Bulk Import</span></h6></a>
                    </div>
                    </div>
                  </div>
     
    </div>
  </div>
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Compliances</b></h3>
              </div>
                <table style={{maxWidth:"100%"}} id="desk_thumb" class="dtgrid1 table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                        <th>S.No.</th>
                       <th>Code</th> 
                      <th>Due Date</th>
                      <th>Month</th>
                      <th>Act Name</th>
                       <th>Rule/Form</th> 
                      <th>Type</th>
                      
                      <th></th> 
                      
                    </tr>
                  </thead>
                  <tbody id="tbData">
                      {(this.state.stComplianceList.length>0?this.state.stComplianceList.map((item,index)=>{
                          
                            return(<tr className='child_desk_thumb'>
                              <td>{index+1}</td>
                              <td>{item["compliance_code"]}</td>
                                <td>{item["f_due_date"]}</td>
                                <td>{item["compliance_month"]}</td>
                                <td>{item["act_name"]}</td>
                                <td>{item["rule_form"]}</td>
                                <td>{item["compliance_type"]+" / "+item["compliance_category"]}</td>
                                
                                <td><a data-toggle="modal" data-target="#divCreateCompliance" onClick={()=>this.setComplianceFillingData(item)} href="javascript:void(0)" className="nav-link1 hover"><span class="badge bg-primary dash_rejected_claim1">View Compliance</span></a></td>
                            </tr>)
                          
                         
                      }):''
                      )}
                  </tbody>
                </table>
             
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
{/* bulk Import */}
<Bulk_Import_Compliance />
{/* Upload document if applicable */}

    {/* end of upd */}
<div className="modal fade bd-example-modal-xl"  id="divCreateCompliance" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Doccument Compliance</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreateCompliance" name="frmCreateCompliance">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="addNewComplianceMaster" />
                            <input type="hidden" name="m_type" id="m_type" value="addNewComplianceMaster" />
                            <input type="hidden" name="state_name" id="state_name"  />
                            <div className="card-body">
                                <div className="row">
                               
                                    <div className="col-lg-9 col-md-9">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Act Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="act_name" name="act_name" className="form-control" placeholder="Act Name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Compliance Month</label>
                                            <div className="input-group input-group-sm">
                                            <select id="compliance_month" name="compliance_month" className="form-control">
                                                    <option value={"0"}>--Select- </option>
                                                {vListMonths.map((item,index)=>{
                                                  return <option value={item["value"]}>{item["display"]}</option>
                                                })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Simplified Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="simplified_name" name="simplified_name" className="form-control" placeholder="Simplified Name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Rule Form</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="rule_form" name="rule_form" className="form-control" placeholder="Rule Form" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">State</label>
                                            <div className="input-group input-group-sm">
                                            <select onChange={(event)=>this.setOnChanageHidden("state_name",event)} className="form-control form-control-sm" id="state_id" name="state_id">
                <option value="0">-Select State-</option>
                {stateList.length>0?stateList.map((item,index)=>{
                                                    return (<option text={item.name}  value={item.location_id}>{item.name}</option>)
                                                  }):''}
              </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                    <div className="form-group">
                                            <label for="exampleInputEmail1">Due Date</label>
                                            <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="due_date" name="due_date" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/MM" data-mask />
                  </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Compliance Type</label>
                                            <div className="input-group input-group-sm">
                                            <select id="compliance_type" name="compliance_type" className="form-control">
                                                    <option value={"0"}>--Select- </option>
                                                {vComplianceType.map((item,index)=>{
                                                  return <option value={item["value"]}>{item["display"]}</option>
                                                })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Applicability</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="applicability" name="applicability" className="form-control" placeholder="Applicability" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Periodicity</label>
                                            <div className="input-group input-group-sm">
                                            <select id="periodicity" name="periodicity" className="form-control">
                                                    <option value="0">--Select--</option>
                                                    {vListPeriodicity.map((item,index)=>{
                                                  return <option value={item["value"]}>{item["display"]}</option>
                                                })}
                                                </select>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Responsibiltiy</label>
                                            <div className="input-group input-group-sm">
                                            <select id="responsibility" name="responsibility" className="form-control">
                                                    <option value="0">--Select--</option>
                                                    {vListReponsibility.map((item,index)=>{
                                                  return <option value={item["value"]}>{item["display"]}</option>
                                                })}
                                                </select>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Weightage</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="weightage" name="weightage" className="form-control" placeholder="Weightage" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Compliance Category</label>
                                            <div className="input-group input-group-sm">
                                            <select id="compliance_category" name="compliance_category" className="form-control">
                                                    <option value="0">--Select--</option>
                                                    {vListComplianceCate.map((item,index)=>{
                                                  return <option value={item["value"]}>{item["display"]}</option>
                                                })}
                                                </select>
                                                
                                            
                                                
                                            </div>
                                        </div>
                                    </div>
                                  
                                 
                                  
                                  
                                  
                                  
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
{/* view document */}
    <div className="modal fade bd-example-modal-xl"  id="divEmpViewDoc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Document Compliance</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmC" name="frmC">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                    <embed width="100%" height="500px" src={"https://acta.indithinkk.com/acta_21.pdf"} toolbar="0"></embed>
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
      </form>
    </div>
    </div>
    </div>
          </div>)
      }
}
export default Compliance_Master_Mgmt;