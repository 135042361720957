import React from 'react';
class Footer extends React.Component{
    render(){
        return(<div><footer className="main-footer fixed-bottom my-bg-color-1"> 
        <strong>Copyright <a target='_blank' href="https://indithinkk.com/">Indithinkk Tech Pvt.Ltd.</a>.</strong> All rights reserved. </footer>
        <div className="container-fluid bg-white fixed-bottom my-shadow d-lg-none d-md-none d-block d-sm-none">
        
          </div>
          </div>
        );
    }
}
export default Footer;